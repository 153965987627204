import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';
import { MenuService } from '../menu/menu.service';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ MenuService ]
})
export class SidenavComponent implements OnInit {
  public userImage1 = '../assets/img/app/easyInLogo3.png';
  @ViewChild('sidenavPS') sidenavPS: PerfectScrollbarComponent;
  public userImage = '../assets/img/users/default-user.jpg';
  public menuItems: Array<any>;
  public settings: Settings;
  constructor(public appSettings: AppSettings, public menuService: MenuService) {
      this.settings = this.appSettings.settings;
  }
  fff1 = localStorage.getItem('email');
  ngOnInit() {
   debugger;
   var array=[];

   var userRole=[];
   this.menuItems = this.menuService.getVerticalMenuItems();
   var payLoad = JSON.parse(window.atob(localStorage.getItem('Mytoken').split('.')[1]));
  var array1=[];
var count=0;
  array1.push(payLoad.role);
  var arr=[];

  arr.push(array1[array1.length-1]);
  
 
     try {
      arr[0].forEach(element => {
         ++count;
      });
     } catch (error) {
   
     }
     
  if(arr==undefined)
   {
      userRole=[]; 
   }
   else if(count==0)
   {
     array1.push("Test");
     userRole.push(array1);
   }
   else
   {
    array1.forEach(element => {
      userRole.push(element);
    });
     
   }
   

  
   if(!userRole[0].some((item) => item == 'ManagePreDefinedTicketReply'))
   {
      array.push('Custome Replies');
   }
   if(!userRole[0].some((item) => item == 'ManageExplore'))
   {
      array.push('Explore');
   }
   if(!userRole[0].some((item) => item == 'ManageAdminUsers'))
   {
      array.push('Users');
   }
   if(!userRole[0].some((item) => item == 'TermsAndConditions'))
   {
     
   }
   if(!userRole[0].some((item) => item == 'ManageServices'))
   {
    
   }
   
   if(!userRole[0].some((item) => item == 'ManageReports'))
   {
      array.push('Reports');
   }
   if(!userRole[0].some((item) => item == 'ManageOwner'))
   {
      array.push('Owners');
   }
   if(!userRole[0].some((item) => item == 'ManagePreDefinedTicketReply') && !userRole[0].some((item) => item == 'ManageUnitTypes') && !userRole[0].some((item) => item == 'ManageCategories'))
   {
     array.push('Definitions');
   }
   if(!userRole[0].some((item) => item == 'ManagePreDefinedTicketReply'))
   {
      array.push('Custome Replies');
   }
   if(!userRole[0].some((item) => item == 'ManageUnitTypes'))
   {
      array.push('Unit Types');
   }
   if(!userRole[0].some((item) => item == 'ManageCategories'))
   {
      array.push('Unit Categories');
   }
   if(!userRole[0].some((item) => item == 'ManageUnits'))
   {
      array.push('Units');
   }
   if(!userRole[0].some((item) => item == 'ManageRegistrationRequest'))
   {
      array.push('Registration Requests');
   }
   
   if(!userRole[0].some((item) => item == 'ManageTicket'))
   {
      array.push('Services');
   }
    if(!userRole[0].some((item) => item == 'ImportData'))
   {
      array.push('Import Data');
   }
   if(!userRole[0].some((item) => item == 'CommunityPolicy'))
   {
      array.push('Resort Rules And Policy');
   }
   if(!userRole[0].some((item) => item == 'ManageBroadcast'))
   {
      array.push('Broadcast');
   }
   console.log(userRole);
   console.log(this.menuItems);
   
   this.menuItems = this.menuItems.filter(d=> !array.includes(d.title));

  }

  public closeSubMenus() {
    const menu = document.querySelector('.sidenav-menu-outer');
    if (menu) {
      for (let i = 0; i < menu.children[0].children.length; i++) {
        const child = menu.children[0].children[i];
        if (child) {
          if (child.children[0].classList.contains('expanded')) {
              child.children[0].classList.remove('expanded');
              child.children[1].classList.remove('show');
          }
        }
      }
    }
  }

  public updatePS(e) {
    this.sidenavPS.directiveRef.update();
  }

}
