// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  mode: 'Development',

  apiUrl : 'https://easyinapi.mnc-control.com/',
  firebaseConfig : {
    apiKey: "AIzaSyA1PGlM1yi8K6vJNu3s5dIgyrzwfBufhHc",
    authDomain: "easy-in-5f358.firebaseapp.com",
    databaseURL: "https://easy-in-5f358.firebaseio.com",
    projectId: "easy-in-5f358",
    storageBucket: "easy-in-5f358.appspot.com",
    messagingSenderId: "512971341795",
    appId: "1:512971341795:web:a939f7d6ae1dc88774ead6",
    measurementId: "G-9B85PHEE5Y"
  },
  whitelistedDomains: ['easyinapi.mnc-control.com'],
  blacklistedRoutes: ['easyinapi.mnc-control.com/ClientAuthentication']

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in
 * mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
